import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';
import { useClickOutside, useDebounce } from 'stimulus-use';

// https://developers.google.com/maps/documentation/javascript/place-autocomplete-data#maps_place_autocomplete_data_session-javascript

const MIN_QUERY_LENGTH = 3;

const removeQueryParams = (url) => {
  const urlObj = new URL(url);
  urlObj.search = '';
  urlObj.hash = '';
  return urlObj.toString();
};

const buildListItem = ({
  name,
  location,
  placeId,
  isCustomAddition = false,
  showBorder = true,
}) => {
  const li = document.createElement('li');

  li.dataset.action =
    'click->place-search#selectPlace keydown.enter->place-search#selectPlace keydown.down->place-search#nextResult keydown.up->place-search#previousResult';
  li.tabIndex = '0';
  if (placeId) {
    li.dataset.placeId = placeId;
  }

  const content = document.createElement('div');
  content.classList =
    'p-3 hover:bg-subtle focus:bg-subtle focus:outline-none	rounded-xl';

  if (isCustomAddition) {
    content.classList = `${content.classList} py-4`;
  }

  const line1 = document.createElement('p');
  line1.innerText = name;
  content.appendChild(line1);

  if (location) {
    const line2 = document.createElement('p');
    line2.classList = 'text-xs';
    line2.innerText = location;
    content.appendChild(line2);
  }

  if (isCustomAddition && showBorder) {
    const border = document.createElement('div');
    border.classList = 'w-full border-subtle border-t-[1.5px] mt-1 mb-2';
    li.appendChild(border);
  }

  li.appendChild(content);

  return li;
};

const getAddressComponent = (addressComponents, targets) => {
  return addressComponents
    .map((c) => {
      return targets.map((target) => {
        if (c.types.includes(target)) {
          return c.shortText;
        }

        return null;
      });
    })
    .flat()
    .filter((n) => n)
    .join(' ');
};

export default class extends Controller {
  static targets = [
    'results',
    'resultsContainer',
    'addressPreview',
    'name',
    'website',
    'phone',
    'googlePlaceId',
    'addressLine1',
    'addressLine2',
    'city',
    'postalCode',
    'state',
    'email',
    'service',
  ];
  static values = {
    apiKey: String,
  };
  static debounces = ['updateQuery'];

  connect() {
    useDebounce(this, { wait: 300 });
    this.initPlaces();
    this.getCurrentLocation();
  }

  resultsContainerTargetConnected() {
    useClickOutside(this, { element: this.resultsContainerTarget });
  }

  clickOutside() {
    this.clearResultsList();
  }

  async initPlaces() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly',
    });

    const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
      await this.loader.importLibrary('places');

    this.Place = Place;
    this.AutocompleteSuggestion = AutocompleteSuggestion;
    this.AutocompleteSessionToken = AutocompleteSessionToken;

    this.refreshToken();
  }

  async updateQuery(event) {
    const query = event.target.value;
    if (!query || query.length <= MIN_QUERY_LENGTH) {
      this.clearResultsList();
      return;
    }

    const request = {
      input: query,
      language: 'en-US',
      region: 'us',
      sessionToken: this.token,
    };

    if (this.currentLocation) {
      // send google user's current location for more localized results
      request.origin = this.currentLocation;
    }

    const { suggestions } =
      await this.AutocompleteSuggestion.fetchAutocompleteSuggestions(request);

    this.clearResultsList();

    suggestions.forEach((suggestion) => {
      const placePrediction = suggestion.placePrediction;

      this.resultsTarget.appendChild(
        buildListItem({
          name: placePrediction?.mainText?.toString(),
          location: placePrediction?.secondaryText?.toString(),
          placeId: placePrediction?.placeId?.toString(),
        }),
      );
    });

    this.resultsTarget.appendChild(
      buildListItem({
        name: `Add '${query}'`,
        isCustomAddition: true,
        showBorder: suggestions.length > 0,
      }),
    );

    this.showResultsList();
  }

  async selectPlace(event) {
    const placeId = event.currentTarget.dataset.placeId;

    if (!placeId) {
      this.clearResultsList();
      return;
    }

    const place = new this.Place({
      id: placeId,
      sessionToken: this.token,
    });

    // https://developers.google.com/maps/documentation/javascript/place-class-data-fields
    await place.fetchFields({
      fields: [
        'displayName',
        'formattedAddress',
        'websiteURI',
        'nationalPhoneNumber',
        'location',
        'addressComponents',
      ],
    });

    // lat/long = place.location

    this.addressPreviewTarget.innerText = place.formattedAddress;
    this.nameTarget.value = place.displayName;
    this.websiteTarget.value = removeQueryParams(place.websiteURI);
    this.phoneTarget.value = place.nationalPhoneNumber;
    this.addressLine1Target.value = getAddressComponent(
      place.addressComponents,
      ['street_number', 'route'],
    );
    this.addressLine2Target.value = getAddressComponent(
      place.addressComponents,
      ['subpremise'],
    );
    this.cityTarget.value = getAddressComponent(place.addressComponents, [
      'locality',
    ]);
    this.stateTarget.value = getAddressComponent(place.addressComponents, [
      'administrative_area_level_1',
    ]);
    this.postalCodeTarget.value = getAddressComponent(place.addressComponents, [
      'postal_code',
    ]);

    // hidden fields
    this.googlePlaceIdTarget.value = place.id;

    this.disableForm();
    this.clearResultsList();
    this.refreshToken();
  }

  showResultsList() {
    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.classList.remove('hidden');
    }
  }

  clearResultsList() {
    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.classList.add('hidden');
    }

    if (this.hasResultsTarget) {
      this.resultsTarget.replaceChildren();
    }
  }

  formFields({ onlyFieldsToBeDisabled }) {
    const fields = [
      this.nameTarget,
      this.addressLine1Target,
      this.addressLine2Target,
      this.cityTarget,
      this.stateTarget,
      this.postalCodeTarget,
      this.websiteTarget,
      this.phoneTarget,
      this.emailTarget,
      this.googlePlaceIdTarget,
    ];

    if (!onlyFieldsToBeDisabled) {
      fields.push(this.serviceTarget);
    }

    return fields;
  }

  disableForm() {
    this.formFields({ onlyFieldsToBeDisabled: true }).forEach((field) => {
      field.readOnly = true;
      field.classList.add('input-disabled');
    });
  }

  clearForm() {
    this.formFields({ onlyFieldsToBeDisabled: false }).forEach((field) => {
      field.readOnly = false;
      field.classList.remove('input-disabled');
      field.value = '';
    });
    this.addressPreviewTarget.innerText = '';
  }

  refreshToken() {
    // tokens are a cost saving measure. they should be refreshed after getting a place id's details
    // https://developers.google.com/maps/documentation/javascript/session-pricing
    this.token = new this.AutocompleteSessionToken();
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position?.coords?.latitude;
      const lng = position?.coords?.longitude;
      if (lat && lng) {
        this.currentLocation = { lat, lng };
      }
    });
  }

  // arrow navigation
  nextResult(event) {
    event.preventDefault();

    const children = this.resultsTarget.children;

    if (children.length <= 0) {
      return;
    }

    if (
      this.activeSelection === undefined ||
      this.activeSelection === children.length - 1
    ) {
      this.activeSelection = 0;
    } else {
      this.activeSelection += 1;
    }

    children[this.activeSelection].focus();
  }

  previousResult(event) {
    event.preventDefault();

    const children = this.resultsTarget.children;

    if (children.length <= 0) {
      return;
    }

    if (this.activeSelection === undefined || this.activeSelection == 0) {
      this.activeSelection = children.length - 1;
    } else {
      this.activeSelection -= 1;
    }

    children[this.activeSelection].focus();
  }
}
